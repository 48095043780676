.BottomButtons {
  box-sizing: border-box;
  width: 93px;
  height: 40px;
  /* background: rgba(255, 255, 255, 0.2) !important; */
  /* #56bae8 */
  background: rgba(255, 255, 255, 0.2) !important;
  border: 2px solid rgba(255, 255, 255, 0.5) !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
  backdrop-filter: blur(10px) !important;
  border-radius: 11px !important;
  align-items: center;
  text-transform: none !important;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: hwb(0 100% 0%);
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
}

.ButtonsGrp {
  display: grid;
  grid-template-columns: 100px 100px;
}

.BtnGroup {
  align-items: center !important;
  padding-left: 69px;
  margin-top: 50px;
}

.Vector {
  display: flex;
  padding-left: 1100px;
  height: 30px;
  margin-top: -50px;
}

.Circles {
  display: grid;
  align-self: center;
  margin-left: 490px;
  padding-top: 20px;
}

.EmTitle {
  width: 323px;
  height: 15px;
  left: 445px;
  padding-top: 22px;
  padding-left: 170px !important;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  color: #ffffff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.ArrowBack {
  color: #ffffff;
  padding-right: 30px !important;
  margin-bottom: -3px;
  width: 16px !important;
  height: 16px !important;
  box-shadow: #242323;
}

.relationship{
  width: 270px;
  height: 35px;
 width: 330px;
    height: 30px;
  color: rgba(0, 0, 0, 0.7);
  padding-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 11px;
  text-align: left;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  background: rgba(255, 255, 255, 0.2);
  font-size: 16px;
}
 .fromEmergency{
  margin-top:12px;
 }
 /* Buttons */  
 .slideshowDot {
  display: inline-block;
  height: 20px;
  width: 50px;
  border-radius: 15px;  
  cursor: pointer;
  margin: 0 auto;  
  background-color: #D9D9D9;
  margin: 0 3px;
}
.slideshowDot.active {
  background-color: #FF8533;
  height: 20px;
  width: 80px;
}
  

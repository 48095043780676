.scroll {
    overflow-y: auto;
    height: 45vh !important;
    width: 52vw;
    margin: 10px 5px; 
    padding: 5px;
    text-align: justify;
}
.scroll2 {
    overflow-y: auto;
    max-height: calc(100vh - 290px);
    height: auto;
    width: 52vw;
    /* margin: 10px 5px;  */
    padding: 5px;
    text-align: justify;
    overflow-x: hidden;
}
.scrolluser {
    overflow-y: scroll;
    height: 57vh !important;
    width: 64.5vw;
    margin: 10px 5px; 
    padding: 5px;
    text-align: justify;
}
.scrolluserEdit {
    overflow-y: scroll;
    height: 57vh !important;
    width: 63.5vw;
    margin: 10px 5px; 
    padding: 5px;
    text-align: justify;
}
.no-scroll {
    height: 50vh;
    width: 52vw;
    margin: 10px 5px;
    padding: 5px;
    text-align: justify;
  }
  
.scroll::-webkit-scrollbar {
    width:7px;
}
.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); 
    border-radius:5px;
}
.scroll::-webkit-scrollbar-thumb {
    border-radius:5px;
    -webkit-box-shadow: inset 0 0 6px #ffffff; 
    background-color: rgb(252, 132, 51);
}

.scroll2::-webkit-scrollbar {
    width:7px;
}
.scroll2::-webkit-scrollbar-track {
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); 
    border-radius:5px;
}
.scroll2::-webkit-scrollbar-thumb {
    border-radius:5px;
    -webkit-box-shadow: inset 0 0 6px #ffffff; 
    background-color: rgb(252, 132, 51);
}

.scrolluser::-webkit-scrollbar {
    width:7px;
}
.scrolluser::-webkit-scrollbar-track {
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); 
    border-radius:5px;
}
.scrolluser::-webkit-scrollbar-thumb {
    border-radius:5px;
    -webkit-box-shadow: inset 0 0 6px #ffffff; 
    background-color: rgb(252, 132, 51);

}
.scrolluserEdit::-webkit-scrollbar {
    width:7px;
}
.scrolluserEdit::-webkit-scrollbar-track {
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); 
    border-radius:5px;
}
.scrolluserEdit::-webkit-scrollbar-thumb {
    border-radius:5px;
    -webkit-box-shadow: inset 0 0 6px #ffffff; 
    background-color: rgb(252, 132, 51);
}
button{
    background-color: #1c93ed;
}

@media screen and (max-width: 890px) {
    .scroll2 {
        max-height: calc(100vh - 377px);
    }
}
.OurStoryWrapper{
    padding: 100px 0px 50px;
}
.OurStoryHeading{
    font-weight: bold;
    font-size: 50px;
    text-align: center;
    color: rgb(31, 85, 165);
    margin: 0;
    padding-bottom: 50px;
}
.StorychangeColor{
    color: #FF8533;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}
.OurStoryContent{
    display: grid;
    grid-template-columns: 0.5fr auto;
    margin-top: 20px;
    grid-gap: 50px;  
    color: #000000;  
}
.StoryText{
    /* color: rgba(47, 102, 126, 1); */
    color: #000000;
    font-weight: 550;
    font-size: 23px;
    text-align: justify;
    line-height: 38px;
    letter-spacing: -0.011em;    
    word-spacing: 0.05em;
}
.StoryImg {
    padding-top: 30px;
}
.StoryImg p {
    text-align: center;
    /* color: rgba(47, 102, 126, 1); */
    color: #000000;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.039em;
    margin-top: 10px;
}
@media (max-width: 1024px){
    .OurStoryWrapper{
        text-align: center;
        padding: 50px 0px 50px;
        
    }
    .OurStoryHeading{
        font-size: 45px;
        padding-bottom: 10px;
    }
    .OurStoryContent{
        grid-template-columns: repeat(1, 1fr);
        align-items: center;
        padding: 10px 0px;
        grid-gap: 0px;
        color: #000000;
    }
    .StoryImg{
        margin-top: 0;
        padding-top: 0;
    }

}
@media (max-width: 768px){
    .OurStoryWrapper{
        padding: 30px 0px;
    }
    .OurStoryHeading{
        font-size: 38px;
        padding: 10px;
    }
    .StoryText{
        font-size: 15px;
        line-height: 28px;
    }
    .StoryImg p{
        font-size: 18px;
        padding: 0;
        margin: 0;
    }
    .StoryImg img{
        width: 350px;
    }
    .StoryImg{
        padding-top: 0;
    }
}
@media (max-width: 480px){
    .OurStoryWrapper{
        padding: 10px 0px;
    }
    .OurStoryHeading{
        font-size: 30px;
        padding: 0px;
    }
    .StoryText{
        font-size: 15px;
    }
    .StoryImg p{
        font-size: 15px;
        padding: 0;
        margin: 0;
    }
    .StoryImg img{
        width: 250px;
    }
    .StoryImg{
        padding-top: 0;
    }

}
.member {
    border-radius: 30px;
}

.EmTitle {
  width: 323px;
  height: 15px;
  left: 445px;
  padding-top: 22px;
  padding-left: 170px !important;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  color: #ffffff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.ArrowBack {
  color: #ffffff;
  padding-right: 30px !important;
  margin-bottom: -3px;
  width: 16px !important;
  height: 16px !important;
  box-shadow: #242323;
}
.fromEmergency{
  margin-top:12px;
 }
@media print {
    .bg-color {
      -webkit-print-color-adjust: exact !important; 
      print-color-adjust: exact !important; 
    }
    .print-grid {
      padding-left: 16.5vw !important;
      -webkit-print-color-adjust: exact !important; 
      print-color-adjust: exact !important; 
    }
    .first-grid {
      margin-left: -8vw !important;
      -webkit-print-color-adjust: exact !important; 
      print-color-adjust: exact !important; 
    }
    .print-grid-state {
        padding-left: 5.5vw !important;
        -webkit-print-color-adjust: exact !important; 
      print-color-adjust: exact !important; 
      }
    .print-grid-eyeColor{
        padding-right: 1vw !important;
        -webkit-print-color-adjust: exact !important; 
      print-color-adjust: exact !important; 
    }
    .empty-grid{
      display:none  !important;
    }
    .company-grid{
      display:flex !important;
      justify-content:  flex-start !important;
      padding-left: -5vw !important;
      margin-left: -7vw !important;
    }
  }

  .max-height{
    height: max-content;
  }
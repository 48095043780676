.StepWrapper {
    max-width: 100%;
    width: auto;
    height: auto;
    background: #FF8533;
    border: 2px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(20px);
    border-radius: 80px;
    align-items: center;
    justify-items: center;
    text-align: center;
    padding: 30px 50px;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.StepWrapper:hover {
    border: 3px solid rgba(255, 255, 255, 0.5);
    box-shadow: 20px 10px 50px rgba(0, 75, 117, 0.3);
    backdrop-filter: blur(20px);
}


.StepWrapper h2 {
    margin: 0 90px;
    background: #ffffff;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    border-radius: 50px;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 22px;
    color: rgba(0, 143, 211, 1);
    padding: 10px;
}

.StepWrapper h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #ffffff;
    /* padding: 0px 30px; */
    text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.StepWrapper p {
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    letter-spacing: -0.011em;
    color: rgba(0, 143, 211, 1);
    text-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
}

.StepWrapper a {
    text-decoration: none;
    font-size: 17px;
    color: #ffffff;
    padding: 8px 10px;
    background: rgba(0, 143, 211, 1);
    border-radius: 30px;
}

@media (max-width: 1300px) {
    .StepWrapper {
        width: 280px;
        height: 454px;
        padding: 50px;
        text-align: center;
    }

    .StepWrapper h2 {
        margin: 0 20px;
        padding: 8px;
        font-size: 15px;
        margin-bottom: 10px;
    }

    .StepWrapper h3 {
        font-size: 20px;
        padding: 0px 10px;
    }

    .StepWrapper p {
        font-size: 18px;
    }
}

.StepWrapper a {
    font-size: 15px;
}

@media (max-width: 1024px) {
    .StepWrapper {
        width: 250px;
        height: 350px;
        padding: 50px;
        text-align: center;
    }

    .StepWrapper h2 {
        font-size: 15px;
        margin-bottom: 10px;
    }

    .StepWrapper h3 {
        font-size: 20px;
        padding: 0px 10px;
    }

    .StepWrapper p {
        font-size: 18px;
    }
}

.StepWrapper a {
    font-size: 15px;
}

@media (max-width: 768px) {
    .WorkWrapper {
        padding: 0px;
    }

    .WorkText {
        font-size: 15px;
    }
}

@media (max-width: 450px) {
    .WorkWrapper {
        padding: 0px;
    }
}

.stepImage {
    max-width: 70%;
    width: auto;
    height: auto;
    object-fit: cover;
}

.StepButton {
    color: rgba(0, 143, 211, 1);
    background-color: white;
    font-size: 22px;
    font-weight: 600;

}

.StepButton a {
    color: rgba(0, 143, 211, 1);
    background-color: transparent;
    font-size: 22px;
    font-weight: 600;
}

@media (max-width: 1026px) {

    .StepButton {
        margin-top: -4vw;

    }
}

@media (max-width: 768px) {
    .StepWrapper {
        width: 300px;
        height: 400px;
        padding: 50px;
        text-align: center;
    }

    .StepButton {
        margin-top: 7vw;

    }

    .stepImage {
        height: "100%";
    }
}

@media (max-width: 977px) {

    .StepButton {
        margin-top: -2vw;

    }
}

@media (max-width: 830px) {

    .StepButton {
        margin-top: 5vw;

    }
}

@media (max-width: 765px) {

    .StepButton {
        margin-top: 7vw;

    }
}

@media (max-width: 660px) {

    .StepButton {
        margin-top: 8vw;

    }
}

@media (max-width: 611px) {

    .StepButton {
        margin-top: 11vw;

    }
}

@media (max-width: 550px) {

    .StepButton {
        margin-top: 14vw;

    }
}

@media (max-width: 450px) {

    .StepButton {
        margin-top: 26vw;

    }
}

@media (max-width: 350px) {

    .StepButton {
        margin-top: 30vw;
    }
}

@media (max-width: 325px) {
    .StepWrapper {
        width: 100%;
    }
}
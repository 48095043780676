/* .table {
  border-radius: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
} */

.tableHeader {
  margin: 0;
  padding: 12px;
  font-size: 12px;
  color: #363741;
  background: rgba(255, 133, 51, 0.20);
}

.tableCell :global(td) {
  margin: 0;
  padding: 5px 12px;
  font-size: 12px;
  color: #363741;
  font-weight: 400;
}
.tableCell :global(tr) {
  border-bottom: 1px solid black;
}

/* .customTable :global(tbody) {
    display: block;
    max-height: 575px;
    overflow: auto;
  } */
.customTable :global(table tbody tr) {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.customTable :global(table thead) {
  display: table;
  table-layout: fixed;
  width: 100%;
}

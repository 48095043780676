.container-verify {
  display: grid;
  justify-content: center;
}
.container-verify form {
  display: grid;
  justify-content: center;
  margin: 220px 0px 80px 0px;
  padding: 0px 45px;
  background-color: #114c6735;
  padding-top: 30px;
  border-radius: 30px;
  flex-direction: column;
  /* box-shadow: inset rgba(255, 255, 255, 0.822) 5px 60px 80px,
      rgba(36, 160, 218, 0.333) 0px 15px 30px; */
  border: 1px solid #249fda;
}
.verifyImage {
  display: grid;
  position: relative;
}
.verifyImage img {
  margin-left: 40px;
  margin-bottom: 20px;
}

.container-verify h2 {
  color: rgba(255, 133, 51, 1);
  text-align: left;
  margin-bottom: 15px;
  margin-top: 10px;
  font-size: 40px;
  font-weight: 600;
  line-height: 150%;
  height: 50px;
  /* width: 300px; */
}

.container-verify h3 {
  color: rgba(255, 133, 51, 1);
  text-align: left;
}

.container-verify p {
  text-align: left;
  padding-left: 20px;
  font-size: 12px;
  color: rgb(218, 31, 31);
}

@media screen and (max-width: 500px) {
  .container-verify {
    display: block;
    float: none;
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 1px;
    padding-bottom: 20px;
  }

  .container-verify h2 {
    margin-top: 1px;
    font-size: 25px;
  }

  .container-verify p {
    text-align: center;
  }
}
@media screen and (max-width: 320px) {
  .container-verify {
    display: block;
    float: none;
    margin-left: 5px;
    margin-right: 5px;
  }

  .container-verify h2 {
    text-align: left;
    font-size: 25px;
  }
}

.btn {
  background-color: rgba(255, 133, 51, 1);
  width: 120px;
  display: block;
  padding: 12px 0;
  margin: 5px auto;
  border-radius: 15px;
  border-style: none;
  cursor: pointer;
  color: rgb(253, 253, 253);
  font-size: 15px;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
  margin: 15px 0 5px 0;
}
.btn:hover {
  transform: translateY(-3px);
  background-color: rgb(106, 46, 6);
  transition: all 0.2s;
}

.btn2 {
  display: flex;
  justify-content: center;
  width: 100px;
  margin: 0;
}

.btn-div {
  display: flex;
  justify-content: center;
}

.form-message {
  display: grid;
  justify-self: left;
}

.checkmark {
  height: 43px;
  width: 40px;
  margin-top: 15px;
}

@media screen and (min-width: 1800px) {
  .container-verify {
    height: calc(100vh - 323px);
  }
}
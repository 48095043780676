.title {
  width: 100%;
  height: 20px;
  left: 81px;
  top: 561px;
  display: flex;
  /* font-family: "omnes", sans-serif; */
  /* font-style: normal; */
  font-weight: 550;
  /* font-weight: 500; */
  font-size: 22px;
  line-height: 190%;
  letter-spacing: 0.01em;
  color: #123444;
}

.ContactInput {
  box-sizing: border-box;
  font-family: "Omnes", sans-serif;
  font-style: normal;
  font-weight: 500;
  display: flex;
  width: 600px;
  height: 38px;
  left: 202.01px;
  top: 755px;
  font-size: 15px;
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #3ca899;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15),
    inset 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 45px;
  margin-bottom: 10px;
}

#name, #address, #email, #comment {
  color:rgba(0, 0, 0, 0.763);
  padding-left: 16px;
  font-size: 17px;
}

.textBox {
  box-sizing: fixed;
  display: flex;
  font-size: 15px;
  font-family: "Omnes", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  line-height: 170%;
  height: 140px;
  left: 209px;
  top: 1380px;
  resize: none;
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #3ca899;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15),
    inset 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.submitbtn {
  height: 55px;
  left: 572.94px;
  top: 15px;
  text-align: center;
  background: #3ca89ae4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 45px;
  border-style: none;
  /* font-family: "Omnes", sans-serif;
  font-style: normal; */
  font-weight: 700;
  font-size: 25px;
  line-height: 99.02%;
  cursor: pointer;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.registerbtn:hover {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 30px 60px rgba(23, 0, 102, 0.1),
    inset 0px 0px 0px 0.1px rgba(255, 255, 255, 0.9);
  transform: translateY(-0.5px);
  background-color: rgba(60, 168, 154, 1.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.inputTitle {
  /* font-family: "Omnes", sans-serif;
  font-style: normal; */
  font-weight: 550;
  font-size: 18px;
  text-align: left;
  padding-top: 20px;
}

.my-container-class {
  /* font-family: "Omnes", sans-serif;
  font-style: normal; */
  /* height: 38px; */
  font-size: 15px;
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #3ca899;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15),
    inset 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 45px;
  margin-bottom: 10px;
}


element.style {
  background: rgba(255, 255, 255, 0);
  border: rgba(255, 255, 255, 0.8);
  padding-top: 0px;
  height: 14px;
  color: rgba(0, 0, 0, 0.765);
}
.react-tel-input .form-control {
  display: grid;
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF00 !important;
  border: 1px solid #CACACA;
  border-radius: 5px;
  line-height: 25px;
  height: 35px !important;
  width: 300px;
  outline: none;
}

@media screen and (max-width: 720px) {
  .ContactInput {
    width: 500px;
  }

  .submitbtn {
    width: 120px;
    height: 40px;
    font-size: 20px;
    font-weight: 600;
  }

  .my-container-class {
    height: 33px;
  }
}

@media screen and (max-width: 620px) {
  .title {
    font-size: 18px;
  }

  .inputTitle {
    font-size: 15px;
  }

  .ContactInput {
    width: 450px;
    height: 32px;
  }

  .my-container-class {
    width: 450px;
    height: 33px;
  }

  .submitbtn {
    font-weight: 200;
    padding-top: 10px;
  }

  #name, #address, #email, #comment{
    padding-left: 14px;
    font-size: 14px;
  }
}


@media screen and (max-width: 520px) {
  .title {
    font-size: 15px;
  }

  .inputTitle {
    font-size: 13px;
    padding-top: 0px;
  }

  .ContactInput {
    width: 350px;
    height: 30px;
    border: 1px solid #3ca899;
  }

  .submitbtn {
    width: 100px;
    height: 30px;
    font-size: 15px;
    font-weight: 500;
    padding-bottom: 25px;
  }

  .my-container-class {
    height: 30px;
    border: 1px solid #3ca899;
  }

  .textBox {
    border: 1px solid #3ca899;
    height: 100px;
  }

  #name, #address, #email, #comment{
    padding-left: 10px;
    font-size: 12px;
  }

  .eDxjeR {
    padding-top: 0px;
    height: 40px !important;
}
}

@media screen and (max-width: 420px) {
  .title {
    font-size: 15px;
    margin-bottom: 3px;
  }

  .inputTitle {
    font-size: 12px;
    height: 30px;
  }

  .ContactInput {
    width: 250px;
    height: 22px;
    margin-bottom: 0px;
    font-size: 11px;
  }

  .submitbtn {
    width: 80px;
    height: 25px;
    font-size: 15px;
    font-weight: 500;
    padding-bottom: 23px;
  }

  .my-container-class {
    height: 22px;
    background-color: none;
    margin-bottom: 0px;
  }

  .my-input-class {
    opacity: 10;
    height: 0px !important;
    width: 200px !important;
    font-size: 10px !important;
    padding-bottom: 10px;
    color:rgba(0, 0, 0, 0.763);
  }

  .textBox {
    height: 70px;
  }

  #name, #address, #email, #comment{
    padding-left: 5px;
    font-size: 10px;
  }

}

@media screen and (max-width: 310px) {
  .title {
    font-size: 15px;
    margin-bottom: 6px;
  }

  .inputTitle {
    font-size: 12px;
    height: 30px;
  }

  .ContactInput {
    width: 250px;
    height: 22px;
    margin-bottom: 0px;
    font-size: 12px;
  }
}

@media screen and (max-width: 305px) {
  .title {
    font-size: 15px;
    margin-bottom: 6px;
  }

  .inputTitle {
    font-size: 12px;
    height: 30px;
  }

  .ContactInput {
    width: 210px;
    height: 20px;
    margin-bottom: 0px;
    font-size: 12px;
  }

  .my-container-class {
    height: 20px;
  }

  .textBox {
    height: 60px;
  }
}

/* overwriting phone number input field */
.react-tel-input .flag-dropdown,
.react-tel-input .selected-flag {
  background-color: transparent !important;
  border: none !important;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .flag-dropdown:hover {
  background-color: transparent !important;
  border: none !important;
}

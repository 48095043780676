.container {
  display: grid;
  justify-content: center;
}
.container form {
  display: grid;
  justify-content: center;
  margin: 195px 0px 80px 0px;
  padding: 0px 45px;
  border: 0.5px solid;
  background-color: #114c6735;
  padding-top: 30px;
  border-radius: 50px;
  flex-direction: column;
  box-shadow: inset rgba(255, 255, 255, 0.822) 5px 60px 80px,
    rgba(36, 160, 218, 0.333) 0px 15px 30px;
  border: 1px solid #249fda;
}
.headerImage {
  display: grid;
  position: relative;
}
.headerImage img {
  margin-left: 100px;
  margin-bottom: 20px;
}
.field {
  display: grid;
  position: relative;
  margin-top: 5px;
  text-align: center;
  width: 380px;
}
.field input {
  display: block;
  width: 98%;
  padding: 14px 0 14px 43px;
  margin: 5px 0;
  border-radius: 20px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: rgba(237, 239, 241, 0.932);
}
button {
  display: block;
  width: 100%;
  padding: 12px;
  margin: 5px auto;
  border-radius: 30px;
  border-style: none;
  cursor: pointer;
}
button a {
  text-decoration: none;
  color: rgb(253, 253, 253);
  font-size: 20px;
}

.pswd {
  height: 20px;
  width: 23px;
  display: flex;
  padding: 2px 2px;
  padding-top: 37px;
  padding-left: 14px;
}
.container h1 {
  color: rgba(6, 71, 108, 1);
  text-align: Center;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 30px;
  font-weight: 100;
  line-height: 100%;
}
.btn1 {
  background-color: rgba(255, 133, 51, 1);
  width: 330px;
  display: block;
  padding: 12px 0;
  margin: 5px auto;
  border-radius: 30px;
  border-style: none;
  cursor: pointer;
  color: rgb(253, 253, 253);
  font-size: 20px;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
}
.btn1:hover {
  transform: translateY(-3px);
  background-color: rgb(106, 46, 6);
  transition: all 0.2s;
}

.container p {
  display: grid;
  text-align: left;
  padding-left: 0px;
  font-size: 12px;
  color: rgb(218, 31, 31);
  width: 380px;
}
.field img {
  position: absolute;
}
.field label {
  justify-self: left;
  font-weight: 500;
  padding-left: -10px;
}

@media screen and (max-width: 500px) {
  .container {
    display: block;
    float: none;
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 1px;
    padding-bottom: 20px;
  }

  .container h1 {
    margin-top: 1px;
    font-size: 25px;
  }
  .field {
    justify-content: center;
    margin-top: 1px;
  }
  .field input {
    width: 270px;
  }

  .field img {
    position: absolute;
    padding-top: 38px;
    padding-left: 68px;
    width: 18px;
    height: 18px;
  }

  button a {
    font-size: small;
  }
  .btn1 {
    width: 195px;
  }

  .container p {
    text-align: center;
    justify-self: center;
    width: 270px;
  }
}
@media screen and (max-width: 320px) {
  .container {
    display: block;
    float: none;
    margin-left: 5px;
    margin-right: 5px;
  }
  .field {
    justify-content: center;
  }
  .field input {
    width: 220px;
  }

  .field img {
    position: absolute;
    padding-top: 38px;
    padding-left: 92px;
    width: 18px;
    height: 18px;
  }

  button a {
    font-size: small;
  }
  .btn1 {
    width: 180px;
  }

  .container h1 {
    text-align: center;
    font-size: 25px;
  }
  .container p {
    width: 220px;
  }
}

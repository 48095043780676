* {
  font-family: sans-serif;
}
.imuContainer h3 {
  font-size: 20px;
  line-height: 23px;
  color: black;
  font-weight: normal;
  margin-left: 100px;
  padding-bottom: 20px;
}
.imuContainer img {
  padding-right: 20px;
}
.form {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  padding-right: 1000px;
  margin-left: 10vw;
}
.form label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1px;
  color: black;
  padding: 20px 0px 10px 0px;
  font-weight: normal;
}
select:focus {
  outline: none !important;
}
/* .form textarea {
  font-family: sans-serif;
  width: 420px;
  height: 297px;
  background: rgba(255, 133, 51, 1);
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 11px;
  padding-bottom: 20px;
  justify-items: center;
  padding-top: 10px;
  padding: 10px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
} */
textarea:focus {
  outline: none !important;
}

.button button {
  box-sizing: border-box;
  width: 113px;
  height: 39px;
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 11px;
  margin: 40px 10px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.button {
  text-align: center;
  justify-items: center;
}
.navIcon {
  text-align: center;
  margin-left: 45px;
}
.log img {
  float: right;
  margin-bottom: 50px;
}
.scrollImmunization {
  overflow-y: auto;
  height: 60vh !important;
  width: 60vw;
  padding: 5px;
  text-align: justify;
  overflow-x: hidden;
}
.no-scrollImmunization {
  height: 60vh;
  width: 64vw;
  margin: 10px 5px;
  padding: 5px;
  text-align: justify;
}

.scrollImmunization::-webkit-scrollbar {
  width: 7px;
}
.scrollImmunization::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.scrollImmunization::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px #ffffff;
  background-color: rgb(252, 132, 51);
}

button {
  background-color: #1c93ed;
}

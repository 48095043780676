.PetInfodata {
  display: grid !important;
  grid-template-columns: repeat(1, 1fr) !important;
  overflow-y: auto;
  max-height: 200px;
  margin-left: 12vw;
}
.PetInfodataTable {
  display: grid !important;
  grid-template-columns: repeat(1, 1fr) !important;
  overflow-y: auto;
  max-height: 30vh;
}
.PetInfodataTable::-webkit-scrollbar {
  width:7px;
}
.PetInfodataTable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  border-radius: 5px;
}
.PetInfodataTable::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px #ffffff;
  background-color: rgb(252,132,51);
}

.Petcoloumn1 {
  text-align: center;
  display: grid;
  grid-template-columns: auto auto;
}
.Petcoloumn2 {
  text-align: center;
  display: grid;
  grid-template-columns: auto auto;
}
.changePet {
  display: grid;
  grid-template-rows: auto;
  position: absolute;
  row-gap: 20px;
  left: 58vw;
  padding-top: 40px;
}
.edit {
  width: 25px;
  height: 25px;
}
@media (max-width: 1450px) {
  .PetInfodata {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
    overflow-y: auto;
    max-height: 200px;
    margin-left: 12vw;
  }
  .Petcoloumn1 #c2 {
    margin-left: -5vw !important;
  }
}

.settingscroll {
    overflow-y: scroll;
    height: 57vh;
    width: 60vw;
    margin: 10px 5px; 
    padding: 10px;
    text-align: justify;
}
.no-scroll {
    height: 57vh;
    width: 66vw;
    margin: 10px 5px;
    padding: 10px;
    text-align: justify;
  }
  
.settingscroll::-webkit-scrollbar {
    width:7px;
}
.settingscroll::-webkit-scrollbar-track {
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); 
    border-radius:5px;
}
.settingscroll::-webkit-scrollbar-thumb {
    border-radius:5px;
    -webkit-box-shadow: inset 0 0 6px #ffffff;
    background-color: rgb(252, 132, 51) 
}

/*profile page*/
.settingprofilescroll {
    overflow-y: scroll;
    height: 57vh;
    width: 66vw;
    margin: 0 none; 
    padding: 10px;
    text-align: center;
}
.no-settingprofilescroll {
    height: 50vh;
    width: 52vw;
    margin: 10px 5px;
    padding: 5px;
    text-align: justify;
  }
  
.settingprofilescroll::-webkit-scrollbar {
    width:7px;
}
.settingprofilescroll::-webkit-scrollbar-track {
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); 
    border-radius:5px;
}
.settingprofilescroll::-webkit-scrollbar-thumb {
    border-radius:5px;
    -webkit-box-shadow: inset 0 0 6px #ffffff;
    background-color: rgb(252, 132, 51) 
}

button{
    background-color: #1c93ed;
}
.GetInTouchWrapper{
    text-align: center;
    margin-bottom: 50px;
}
.GetTopic{
    font-weight: 400;
    font-size: 50px;
    text-align: center;
    /* color: rgb(31, 85, 165); */
    color: #000000;
    margin-top: 20px;
    padding-bottom: 50px;
    letter-spacing: 0.05em;
    padding: 20px 50px;
}
.GetTopic span{
    color: rgba(93, 186, 230, 1);
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.GetInTouchWrapper a {
    background: linear-gradient(0deg, #FF8533, #FF8533), #FF8533;
    border: 2px solid #FF8533;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    font-size: 24px;
    color: white;
    text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    padding: 10px 30px;
    margin-top: 30px;
    cursor: pointer;
    outline: none;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    text-decoration: none;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.011em;
}
.GetInTouchWrapper a:hover{
    box-shadow: 0 10px 20px rgba(0,0,0,0.25);
    transform: translateY(-10px);
}
@media (max-width: 1024px){
    .GetInTouchWrapper{
        padding: 50px 70px 50px;
        margin-bottom: 50px;
    }
    .GetTopic{
        font-size: 45px;
        padding: 20px 0px;
    }
}

@media (max-width: 768px){
    .GetInTouchWrapper{
        padding: 0px 30px;
        margin-bottom: 50px;
    }
    .GetTopic{
        font-size: 38px;
    }
    .GetInTouchWrapper a{
        font-size: 20px;
    }
}
@media(max-width: 480px){
    .GetInTouchWrapper a{
        font-size: 15px;
    }
    .GetTopic{
        font-size: 30px;
        padding: 20px 0px;
    }
}

@media(max-width: 450px){
    .GetInTouchWrapper{
        padding: 0px 20px;
    }
}
.WorkWrapper{
    padding: 100px 0px 50px;
}
.WorkHeader{
    font-weight: bold;
    font-size: 50px;
    text-align: center;
    color: rgb(31, 85, 165);
    margin: 0;
    padding-bottom: 20px;
}

.WorkHeader span{
    color: #FF8533;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.WorkText{
    /* color: rgba(47, 102, 126, 1); */
    color: #000000;
    font-weight: 500;
    font-size: 23px;
    text-align: center;
    line-height: 38px;
    letter-spacing: -0.011em;
}

@media (max-width: 1024px){
    .WorkWrapper{
        text-align: center;
        padding: 50px 0px 50px;
    }
    .WorkHeader{
        font-size: 45px;
    }
}

@media (max-width: 768px){
    .WorkWrapper{
        padding: 0px;
    }
    .WorkHeader{
        font-size: 38px;
        padding: 20px 0px 0px 0px;
    }
    .WorkText{
        font-size: 15px;
        line-height: 28px;
    }
}

@media (max-width: 480px){
    .WorkHeader{
        font-size: 30px;
    }
}
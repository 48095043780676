/* .A textArea {
  width: 330px;
  height: 207px;
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  padding-bottom: 20px;
  justify-items: center;
  padding-top: 10px;
  padding: 10px;
  color: white;
  font-size: 18px;
  resize: none;
} */
@media screen and (max-width: 1199px) {
  .A textarea {
    width: 250px;
  }
}
@media screen and (max-width: 1049px) {
  .A textarea {
    width: 210px;
  }
}
@media screen and (max-width: 940px) {
  .A textarea {
    width: 200px;
  }
}
@media screen and (max-width: 900px) {
  .A textarea {
    width: 200px;
  }
}
@media screen and (max-width: 700px) {
  .A textarea {
    width: 270px;
  }
}
@media screen and (max-width: 650px) {
  .A textarea {
    width: 230px;
  }
}
@media screen and (max-width: 600px) {
  .A textarea {
    width: 270px;
  }
}

#DOB {
  color:black;
}

.PresVector {
  display: flex;
  padding-left: 1100px;
  height: 30px;
  margin-top: -50px;
}

.BtnGroup {
  padding-bottom: 0px;
}

.PresCircles {
  display: grid;
  align-self: center;
  margin-left: 490px;
  padding-top: 20px;
}

.Pbtn {
  display: grid;
  grid-template-columns: 50% 50%;
}

.PresForm {
  display: flex;
  justify-content: center;
}
.PresForm2 {
  display: flex;
  justify-content: right;
}

.pbtn {
  color: "white";
  background: "rgba(255, 255, 255, 0.2)";
  border: "2px solid rgba(255, 255, 255, 0.5)";
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  width: 100px;
  height: 40px;
}

.BottomButtons {
  box-sizing: border-box;
  background: #ffffff33 !important;
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
  backdrop-filter: blur(10px) !important;
  border-radius: 25px !important;
  width: 138px;
  height: 37px;
  text-transform: none !important;
  /* margin-left: -100px !important;
  margin-right: -100px !important; */
}

.EmTitle {
  width: 323px;
  height: 15px;
  left: 445px;
  padding-top: 22px;
  padding-left: 190px;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  color: #ffffff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.ArrowBack {
  color: #ffffff;
  padding-right: 30px;
  margin-bottom: -3px;
  width: 16px !important;
  height: 16px !important;
  box-shadow: #242323;
}

.InputLabel {
  margin-bottom: 3px;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: #ffffff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  padding-left: 5px;
  margin-top: 13px;
}

.Share {
  padding-top: 4px;
}

.date {
  color: black;
  color-scheme: light;
}

.Wrapper{
    padding: 100px 0px 0px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}
.TitleWrapper{
  padding-top: 0;
  font-weight: 500;
  font-size: 50px;
  line-height: 129.4%;
  color:rgb(31, 85, 165);
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}
.BodyWrapper p {
  padding-top: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 38px;
  text-align: right !important;
  letter-spacing: 0.039em;
  /* color: #06476c; */
  color: #000000;
  font-weight: bold;
}
.BodyWrapper a{
    background: linear-gradient(0deg, #FF8533, #FF8533), #FF8533;
    border: 2px solid #FF8533;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    font-size: 23px;
    color: white;
    text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    outline: none;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    text-decoration: none;
    font-weight: bold;
}
.BodyWrapper a:hover{
    box-shadow: 0 10px 20px rgba(0,0,0,0.25);
    transform: translateY(-3px);
    
}
@media (max-width: 1100px){
    .TitleWrapper{
        font-size: 45px;
    }
    .BodyWrapper p{
        font-size: 22px;
    }
    .BodyWrapper a{
        font-size: 22px;
    }
}
@media (max-width: 1024px){
    .Wrapper{
        grid-template-rows: auto;
        padding: 70px 0px;
    }
    .TitleWrapper{
        font-size: 45px;
    }
    .BodyWrapper p{
        font-size: 20px;
    }
    .BodyWrapper a{
        font-size: 20px;
    }
}
@media (max-width: 800px){
    .Wrapper{
        padding: 50px 0px;
    }
    .TitleWrapper{
        font-size: 40px;
    }
    .BodyWrapper p{
        font-size: 15px;
        line-height: 28px
    }
    .BodyWrapper a{
        font-size: 15px;
    }
}
@media (max-width: 650px){
    .Wrapper{
        display: grid;
        padding: 30px 0px;
        grid-template-columns: repeat(1, 1fr);

    }
    .TitleWrapper{
        font-size: 30px;
    }
    .BodyWrapper p{
        font-size: 15px;
        text-align: justify;
        justify-items: center;
        
    }
    .BodyWrapper a{
        font-size: 15px;
        text-align: center;
        
    }
}

@media (max-width: 450px){
    .Wrapper{
        padding: 20px 0px;
    }
    .TitleWrapper{
        font-size: 30px;
    }
    .BodyWrapper p{
        font-size: 15px;
        text-align: left !important;
        justify-items: center;
        
    }
    .BodyWrapper a{
        font-size: 15px;
        text-align: center;
        
    }
}